import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { TimesheetPayrollTherapist } from '@/views/timesheet-details/interfaces/timesheet-payroll-therapist.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as _ from 'lodash';

interface Params {
  id?: string;
  therapistId?: string;
  skip: number;
  take: number;
  sortField?: string | null;
  sortOrder?: 'ASC' | 'DESC';
}

type Response = {
  data: TimesheetPayrollTherapist[];
  totalCount: number;
};

export type Options = Omit<UseQueryOptions<unknown, DefaultError, Response>, 'queryKey' | 'queryFn'>;

export const useTimesheetPayrollQuery = (params: Params, options?: Options) => {
  const queryParams = {
    skip: params.skip,
    take: params.take,
    sortField: params.sortField,
    sortOrder: params.sortField,
  };
  const cleanedQueryParams = _.omitBy(queryParams, (value) => _.isNil(value));

  const _query = async (): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.get(
      params.therapistId
        ? `timesheets/${params.id}/payroll-entries/${params.therapistId}`
        : `timesheets/${params.id}/payroll-entries`,
      {
        params: cleanedQueryParams,
      }
    );
    return data;
  };

  return useQuery({
    queryKey: ['timesheets', params.id, 'payroll-entries', params.therapistId, queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
