import { Dialog } from '@/components/ui';
import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { DialogDescription } from '@radix-ui/react-dialog';

import { useTranslations } from 'next-intl';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Client, ParsedClientFromPDF } from '../interfaces/clients.interface';
import { CreateClientAndGuardianForm } from './create-client-and-guardian-form';
import { useOCRParseMutation } from '@/hooks/fetchers/mutations/ocr/useOCRParseMutation';
import { toast, ToastTypeEnums } from '@/components/common';
import { useLongPollingFileQuery } from '@/hooks/fetchers/queries/useLongPollingFileQuery';
import { Loader2 } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  onCreated: (client: Client) => void;
}

export type CreateClientAndGuardianDialogRef = {
  open: (file: File) => void;
};

const CreateClientAndGuardianDialog = forwardRef<CreateClientAndGuardianDialogRef, Props>(({ onCreated }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [client, setClient] = useState<ParsedClientFromPDF>();
  const [optionLongPolling, setOptionLongPolling] = useState<{ fileId?: string; refetchInterval: number }>({
    fileId: undefined,
    refetchInterval: 2000,
  });

  const t = useTranslations();
  const queryClient = useQueryClient()

  const {
    data,
    refetch: refetchLongPolling,
    isSuccess,
    isError,
  } = useLongPollingFileQuery(optionLongPolling.fileId, {
    staleTime: 0, // This ensures the data is always considered stale
    refetchInterval: optionLongPolling.refetchInterval,
    refetchIntervalInBackground: true,
    enabled: false,
    refetchOnWindowFocus: true,
    throwOnError: true,
  });

  const { mutate: uploadFileForParse } = useOCRParseMutation({
    onSuccess: (res) => {
      if (res.data.fileId) {
        setOptionLongPolling({
          refetchInterval: 2000,
          fileId: res.data.fileId,
        });

      }
    },
  });

  useEffect(() => {
    if (optionLongPolling.refetchInterval > 0 && optionLongPolling.fileId) {
      refetchLongPolling();
    }
  }, [optionLongPolling]);


  useEffect(() => {
    queryClient.cancelQueries({queryKey: ['long-polling']})
    setClient(undefined);
    setOptionLongPolling({ fileId: undefined, refetchInterval: 2000 });
  }, [isOpen]);

  useEffect(() => {
    if (isError) {
      if (isOpen) {
        toast({
          title: t('Toasts.error'),
          typeIcon: ToastTypeEnums.ERROR,
          description: t('Toasts.fileParsedError'),
        });
      }
      setOptionLongPolling({ fileId: undefined, refetchInterval: 2000 });
      setIsOpen(false);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      if (isOpen) {
        toast({
          title: t('Toasts.success'),
          typeIcon: ToastTypeEnums.SUCCESS,
          description: t('Toasts.fileParsed'),
        });
        setClient(data);
      }
      setOptionLongPolling((prev) => ({
        ...prev,
        refetchInterval: 0,
      }));
    }
  }, [isSuccess]);

  useImperativeHandle(ref, () => ({
    open: (file) => {
      uploadFileForParse({ file });
      setIsOpen(true);
    },
  }));

  const handleCreate = (client: Client) => {
    setIsOpen(false);
    onCreated(client);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-screen-xl">
          <DialogHeader>
            <DialogTitle>{t('Pages.AllClients.createClientGuardian')}</DialogTitle>
            <DialogDescription>{t('Pages.AllClients.createClientGuardianDesc')}</DialogDescription>
          </DialogHeader>
          <div className="pt-4">
            {!client && (
              <div className="flex h-[calc(100vh-196px)] flex-col items-center justify-center">
                <Loader2 className="size-10 animate-spin text-gray-400" />
                <p className="mt-4 font-normal text-black/40">{t('Common.fileProcessing')}</p>
              </div>
            )}
            {client && (
              <CreateClientAndGuardianForm
                client={client}
                fileId={optionLongPolling.fileId as string}
                onCreate={(client) => handleCreate(client)}
                onCancel={() => setIsOpen(false)}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
});

CreateClientAndGuardianDialog.displayName = 'CreateClientAndGuardianDialog';

export { CreateClientAndGuardianDialog };
