import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { DialogDescription } from '@radix-ui/react-dialog';

import { useTranslations } from 'next-intl';
import { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Admin } from './interfaces/admins.interface';
import { toMaskByString } from '@/components/form';
import { LabelValueItem, toast, ToastAction, ToastTypeEnums } from '@/components/common';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { compileLastName } from '@/lib/utils';
import { UserTypes, useUserResendLinkMutation } from '@/hooks/fetchers/mutations/useUserResendLinkMutation';
import { ManageAdminDialog, ManageAdminDialogRef } from './manage-admin.dialog';
import { useAdminQuery } from '@/hooks/fetchers/queries/admins/useAdminQuery';
import { useRemoveAdminMutation } from '@/hooks/fetchers/mutations/admins/useRemoveAdminMutation';
import { useRemoveUndoAdminMutation } from '@/hooks/fetchers/mutations/admins/useRemoveUndoAdminMutation';

interface Props {
  onUpdated?: () => void;
  onRemove?: (id: string) => void;
}

export type PreviewAdminDialogRef = {
  open: (account: Admin) => void;
  openById: (id: string) => void;
};

const PreviewAdminDialog = forwardRef<PreviewAdminDialogRef, Props>(({ onUpdated, onRemove }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const [admin, setAdmin] = useState<Admin | null>(null);
  const [adminId, setAdminId] = useState<string>();
  const { checkPermissions } = useIsAllowed();
  const manageAdminDialogRef = createRef<ManageAdminDialogRef>();

  const { refetch: refetchAdmin, data: adminById } = useAdminQuery(adminId, {
    enabled: false,
    refetchOnWindowFocus: isOpen,
  });

  useImperativeHandle(ref, () => ({
    open: (admin) => {
      setIsOpen(true);
      setAdmin(admin);
      setAdminId(admin.id);
    },
    openById: (id) => {
      setIsOpen(true);
      setAdminId(id);
    },
  }));

  useEffect(() => {
    refetchAdmin();
  }, [adminId]);

  useEffect(() => {
    if (!isOpen && adminId) {
      setAdminId(undefined);
    }
  }, [isOpen]);

  const { mutate: resendLink, isPending } = useUserResendLinkMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
      });
      onUpdated?.();
    },
  });

  const { mutate: removeAdmin } = useRemoveAdminMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndoAdmin(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      setIsOpen(false);
      onUpdated?.();
      onRemove?.(data.id);
    },
  });

  const { mutate: removeUndoAdmin } = useRemoveUndoAdminMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      onUpdated?.();
    },
  });

  const account = adminById ? adminById : admin;

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-screen-xl">
          <DialogHeader>
            <DialogTitle>{t('Pages.AdminUsersList.previewProfile')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>

          <div className="h-[calc(100vh-320px)]">
            <div className="mb-6 flex w-full max-w-[676px] flex-col space-y-6">
              <LabelValueItem label={t('Forms.firstName.label')} value={account?.firstName} />
              <LabelValueItem label={t('Forms.lastName.label')} value={compileLastName(account as Admin)} />
              <LabelValueItem label={t('Forms.email.label')} value={account?.email} />
            </div>
            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              <LabelValueItem
                label={t('Forms.phoneNumber.label')}
                value={account ? toMaskByString(account.phone) : undefined}
              />
              <LabelValueItem label={t('Forms.accessLevel.label')} value={account?.role} />
            </div>
          </div>

          <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
            {!account?.deactivatedAt && (
              <>
                {checkPermissions([PERMISSIONS_TYPES.RESEND_INVITATION_LINK]) && !account?.isAuthorizationFinished ? (
                  <Button
                    disabled={isPending}
                    type="button"
                    size="lg"
                    variant="outline"
                    className="mt-5"
                    onClick={() => resendLink({ id: account?.id as string, type: UserTypes.ADMINS })}
                  >
                    {t('Buttons.resendLink')}
                  </Button>
                ) : null}
                {checkPermissions([PERMISSIONS_TYPES.CHANGE_ADMIN_PROFILE]) ? (
                  <Button
                    type="button"
                    size="lg"
                    className="mt-5"
                    onClick={() => manageAdminDialogRef.current?.open(account as Admin)}
                  >
                    {t('Buttons.edit')}
                  </Button>
                ) : null}
              </>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <ManageAdminDialog onUpdated={refetchAdmin} onDelete={(id) => removeAdmin(id)} ref={manageAdminDialogRef} />
    </>
  );
});

PreviewAdminDialog.displayName = 'PreviewAdminDialog';

export { PreviewAdminDialog };
