import { ColumnDef } from '@tanstack/react-table';
import dayjs from '@/lib/dayjsConfig';
import { TimesheetPayrollTherapist } from '../interfaces/timesheet-payroll-therapist.interface';
import { capitalizeFirstLetter, getColorForServiceRole, minutesToTime } from '@/lib/utils';
import { Chips } from '@/components/common';

type Params = {
  t: any;
  onPreviewService: (serviceId: string) => void;
};

export const getDetailedCalculationColumns = ({ t, onPreviewService }: Params): ColumnDef<TimesheetPayrollTherapist>[] => {
  const columns: ColumnDef<TimesheetPayrollTherapist>[] = [
    {
      id: 'Service',
      header: () => <span>{t('Tables.service')}</span>,
      cell: ({ row }) => {
        if (row.original.shift && row.original.shift.service) {
          return (
            <div className="flex flex-row flex-wrap gap-1 py-2">
              <Chips
                onClickAction={(e) => {
                  onPreviewService(row.original.shift.service.id);
                  e.stopPropagation();
                }}
                color={getColorForServiceRole(row.original.shift.service.title)}
                title={row.original.shift.service.title}
              />
            </div>
          );
        }
      },
      size: 250,
      enableSorting: false,
      enablePinning: false,
    },
    {
      id: 'Type',
      accessorKey: 'workType',
      enablePinning: false,
      enableSorting: false,
      accessorFn: (row) => (row.workType ? capitalizeFirstLetter(row.workType) : '---'),
      header: () => <span>{t('Tables.type')}</span>,
      size: 140,
    },
    {
      id: 'Date',
      enablePinning: false,
      enableSorting: true,
      header: () => <span>{t('Tables.date')}</span>,
      accessorFn: (row) => (row.date ? dayjs(row.date).format('MM.DD.YYYY') : '---'),
      size: 140,
    },
    {
      id: 'Hours',
      accessorKey: 'duration',
      enablePinning: false,
      enableSorting: false,
      header: () => <span>{t('Tables.hours')}</span>,
      accessorFn: (row) => (row.duration ? minutesToTime(row.duration) : '---'),
      size: 140,
    },
    {
      id: 'Pay Rate',
      accessorKey: 'payRate',
      enablePinning: false,
      enableSorting: false,
      header: () => <span>{t('Tables.payRate')}</span>,
      accessorFn: (row) => (row.payRate ? `$${row.payRate}` : '---'),
      size: 140,
    },
    {
      id: 'Total Pay',
      accessorKey: 'totalPay',
      enablePinning: false,
      enableSorting: false,
      header: () => <span>{t('Tables.totalPay')}</span>,
      accessorFn: (row) => (row.totalPay ? `$${row.totalPay.toFixed(2)}` : '---'),
      size: 140,
    },
  ];

  return columns;
};
