import { validatePhone } from '@/components/form';
import { z } from 'zod';


export const profileInfoValidationSchema = z
  .object({
    firstName: z.string().min(1, { message: 'Forms.required' }),
    lastName: z.string().min(1, { message: 'Forms.required' }),
    phoneNumber: z.string().nullable(),
    role: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.phoneNumber && data.phoneNumber.length > 0) {
      if (!validatePhone(data.phoneNumber)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['phoneNumber'],
          message: 'Forms.invalid',
        });
      }
    }
  });

export type ProfileInfoValidationSchema = z.infer<typeof profileInfoValidationSchema>;
