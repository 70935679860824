import { cn } from '@/lib/utils';
import { useRouter } from 'next/navigation';
import Image from 'next/image';

interface Props {
  path: string;
  svgPath: string;
  label: string;
  className?: string;
}

export function NavItem({ path, svgPath, className, label }: Props) {
  const router = useRouter();

  const handleRedirect = () => {
    router.push(path);
  };

  return (
    <div
      onClick={handleRedirect}
      className={cn(
        'flex h-[72px] min-w-36 cursor-pointer flex-col items-start rounded-md border border-gray-200 p-2 px-4',
        className
      )}
    >
      <Image width={24} height={24} alt="Badge Icon" src={svgPath} />
      <span className="mt-1 whitespace-nowrap text-base font-medium text-gray-800">{label}</span>
    </div>
  );
}
