import { Avatar, AvatarFallback, Button } from '@/components/ui';
import { cn } from '@/lib/utils';
import { timestampToTime } from '../utils/messageUtils';
import { Check, CheckCheck, EllipsisVertical } from 'lucide-react';
import { SendbirdChatWith } from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import { BaseMessage, UserMessage } from '@sendbird/chat/message';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { saveAs } from 'file-saver';
import { MessageFileContainer } from './message-file-container';
import { MessageImgContainer } from './message-img-container';
import { useTranslations } from 'next-intl';
import { ChatTypes } from '../channel/channel-list';

interface MessageProps {
  className?: string;
  message: any;
  isDepartmentAdmin: boolean;
  readMessages: BaseMessage[] & UserMessage[];
  sb: SendbirdChatWith<GroupChannelModule[]>;
  messageSentByYou: boolean;
  chatType: ChatTypes;
  handleDeleteMessage: (message: BaseMessage & UserMessage) => void;
  updateMessage: (message: BaseMessage & UserMessage) => void;
}

export function Message({
  className,
  sb,
  message,
  readMessages,
  isDepartmentAdmin,
  messageSentByYou,
  handleDeleteMessage,
  updateMessage,
}: MessageProps) {
  const t = useTranslations();
  const messageSentByCurrentUser = message.sender.userId === sb.currentUser?.userId;
  let isMessagesRead;

  if (messageSentByCurrentUser) {
    isMessagesRead = readMessages.filter((readMessage) => readMessage.messageId === message.messageId).length > 0;
  }

  const handleDownloadFile = () => {
    saveAs(message.url, message.name);
  };

  let container: JSX.Element;

  
  if (message.url && !message.type.includes('image')) {
    container = <MessageFileContainer message={message} onDownload={handleDownloadFile} />;
  } else if (message.url && message.type.includes('image')) {
    container = <MessageImgContainer message={message} onDownload={handleDownloadFile} />;
  } else {
    container = (
      <div className="flex flex-col rounded-e-xl rounded-es-xl border-gray-200 bg-gray-100 p-4">
        <p className="text-sm font-normal text-gray-900">{message.message}</p>
      </div>
    );
  }

  if (message.sendingStatus === 'pending') {
    container = (
      <div className="flex flex-row rounded-e-xl rounded-es-xl border-gray-200 bg-gray-100 p-4">
        <div
          className="inline-block size-6 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-blue-400 motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
        <p className="text-sm font-normal text-gray-900 ml-4">Uploading...</p>
      </div>
    );
  }

  return (
    <div className={cn('flex items-start gap-2.5', className)}>
      <Avatar className="size-8 rounded-full">
        {/* <AvatarImage src="https://github.com/shadcn.png" alt="Profile picture" /> */}
        <AvatarFallback>{message.messageType === 'admin' ? 'A' : message.sender.nickname.charAt(0)}</AvatarFallback>
      </Avatar>
      <div className="flex flex-col gap-1">
        <div className="flex items-center space-x-2 rtl:space-x-reverse">
          <span className="text-sm font-semibold text-gray-900">
            {message.messageType === 'admin' || (isDepartmentAdmin ? 'ADMIN' : message?.sender.nickname)}
          </span>
          <span className="flex flex-row text-sm font-normal text-gray-500">
            {timestampToTime(message.createdAt)}{' '}
            {messageSentByCurrentUser &&
              (isMessagesRead ? <CheckCheck className="ml-2 size-4" /> : <Check className="ml-2 size-4" />)}
          </span>
        </div>
        {container}
      </div>
      {message.messageType !== 'admin' && messageSentByYou && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className="mt-6">
              <EllipsisVertical className="w-5 text-gray-400" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuGroup>
              {message.messageType !== 'file' && (
                <DropdownMenuItem className="cursor-pointer" onClick={() => updateMessage(message)}>
                  {t('Buttons.edit')}
                </DropdownMenuItem>
              )}
              <DropdownMenuItem className="cursor-pointer" onClick={() => handleDeleteMessage(message)}>
                {t('Buttons.delete')}
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
}
