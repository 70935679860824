import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';

import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { SimpleSearch, ScrollArea } from '@/components/common';
import { Checkbox, TagItem } from '@/components/form';
import { useClientsQuery } from '@/hooks/fetchers/queries/clients/useClientsQuery';
import { Client } from '../all-clients/interfaces/clients.interface';
import { useTherapistsQuery } from '@/hooks/fetchers/queries/therapists/useTherapistsQuery';
import { Therapist } from '../all-therapists/interfaces/therapists.interface';

export enum FilterAccountsType {
  CLIENTS = 'clients',
  THERAPISTS = 'therapist',
}

interface Props {
  onApply: (accounts: SelectAccountsFilterData) => void;
}

export interface SelectAccountsFilterData {
  selectedClients: string[];
  selectedTherapists: string[];
  type: FilterAccountsType;
}

export type SelectAccountsDialogRef = {
  open: (accounts: SelectAccountsFilterData) => void;
};

const SelectAccountsDialog = forwardRef<SelectAccountsDialogRef, Props>(({ onApply }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const [selectedClients, setSelectedClients] = useState<any[]>([]);
  const [selectedTherapists, setSelectedTherapists] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState<FilterAccountsType>(FilterAccountsType.CLIENTS);
  const [keywordClient, setKeywordClient] = useState('');
  const [keywordTherapist, setKeywordTherapist] = useState('');

  const { data: therapists } = useTherapistsQuery<TagItem[]>(
    {
      search: keywordTherapist,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((therapist: Therapist) => ({
          label: `${therapist.firstName} ${therapist.lastName}`,
          value: therapist,
        })),
    }
  );

  const { data: clients } = useClientsQuery<TagItem[]>(
    {
      search: keywordClient,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((client: Client) => ({
          label: `${client.firstName} ${client.lastName}`,
          value: {
            id: client.id,
            firstName: client.firstName,
            lastName: client.lastName,
          },
        })),
    }
  );

  useImperativeHandle(ref, () => ({
    open: (accounts) => {
      setSelectedClients(accounts.selectedClients ?? []);
      setSelectedTherapists(accounts.selectedTherapists ?? []);
      setKeywordClient('');
      setKeywordTherapist('');
      setIsOpen(true);

      if (accounts.selectedTherapists && accounts.selectedTherapists.length > 0) {
        setSelectedTab(FilterAccountsType.THERAPISTS);
      }
    },
  }));

  const handleApply = () => {
    setIsOpen(false);
    onApply({ selectedClients, selectedTherapists, type: selectedTab });
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full min-w-[400px]">
        <DialogHeader>
          <DialogTitle>{t('Pages.Schedule.selectAccountsTitle')}</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <Tabs
          value={selectedTab}
          onValueChange={(value) => {
            setSelectedTab(value as FilterAccountsType);
            setKeywordClient('');
            setKeywordTherapist('');
          }}
          defaultValue={FilterAccountsType.CLIENTS}
        >
          <TabsList className="w-full">
            <TabsTrigger className="w-full" value={FilterAccountsType.CLIENTS}>
              {t('Buttons.clients')}
            </TabsTrigger>
            <TabsTrigger className="w-full" value={FilterAccountsType.THERAPISTS}>
              {t('Buttons.therapists')}
            </TabsTrigger>
          </TabsList>
          <TabsContent value={FilterAccountsType.CLIENTS}>
            <SimpleSearch className="mb-2 w-full" onSearchChange={(e) => setKeywordClient(e.target.value)} />
            <ul>
              <ScrollArea className="h-96">
                {clients?.map((item) => (
                  <li key={item.value.id} className="flex min-h-12 items-center border-b px-3">
                    <Checkbox
                      aria-label="Select row"
                      className="translate-y-[2px]"
                      checked={selectedClients.includes(item.value.id)}
                      onCheckedChange={(v) => {
                        if (v) {
                          setSelectedClients([...selectedClients, item.value.id]);
                        } else {
                          setSelectedClients(selectedClients.filter((selected) => selected !== item.value.id));
                        }
                      }}
                    />
                    <input hidden type="checkbox" value={item.value.id} />
                    <span className="ml-4 pt-1 text-base text-gray-700">{item.label}</span>
                  </li>
                ))}
              </ScrollArea>
            </ul>
          </TabsContent>
          <TabsContent value={FilterAccountsType.THERAPISTS}>
            <SimpleSearch className="mb-2 w-full" onSearchChange={(e) => setKeywordTherapist(e.target.value)} />
            <ul>
              <ScrollArea className="h-96">
                {therapists?.map((item) => (
                  <li key={item.value.id} className="flex min-h-12 items-center border-b px-3">
                    <Checkbox
                      aria-label="Select row"
                      className="translate-y-[2px]"
                      checked={selectedTherapists.includes(item.value.id)}
                      onCheckedChange={(v) => {
                        if (v) {
                          setSelectedTherapists([...selectedTherapists, item.value.id]);
                        } else {
                          setSelectedTherapists(selectedTherapists.filter((selected) => selected !== item.value.id));
                        }
                      }}
                    />
                    <input hidden type="checkbox" value={item.value.id} />
                    <span className="ml-4 pt-1">{item.label}</span>
                  </li>
                ))}
              </ScrollArea>
            </ul>
          </TabsContent>
        </Tabs>

        <DialogFooter className="mt-4 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
          <Button onClick={() => setIsOpen(false)} variant="ghost" type="button" size="lg" className="mt-5">
            {t('Buttons.cancel')}
          </Button>
          <Button type="button" size="lg" className="mt-5" onClick={handleApply}>
            {t('Buttons.apply')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
});

SelectAccountsDialog.displayName = 'SelectAccountsDialog';

export { SelectAccountsDialog };
