'use client';
import { ListFilter } from 'lucide-react';
import { Button, Popover, PopoverContent, PopoverTrigger } from '@/components/ui';
import { useTranslations } from 'next-intl';
import { TagItem, Tag } from '@/components/form';
import { cn } from '@/lib/utils';
import { useCallback, useMemo, useState } from 'react';

const VISIBLE_COUNT = 3;

interface FormComponentProps<K> {
  value?: K;
  onApply: (value: K) => void;
  onCancel: () => void;
}

interface AuditLogFilterProps<T> {
  filter: T;
  className?: string;
  onUpdateFilter: (value: T) => void;
  parseFilterToArrayOfTagsData: (filter: T) => TagItem[];
  clearFilter: (filter: T, filterValue: TagItem, field: string) => T;
  formComponent: ({ value, onApply, onCancel }: FormComponentProps<T>) => JSX.Element;
}

export function AuditLogFilter<T>({
  filter,
  className,
  clearFilter,
  onUpdateFilter,
  parseFilterToArrayOfTagsData,
  formComponent,
}: AuditLogFilterProps<T>) {
  const t = useTranslations();
  const [filterOpen, setFilterOpen] = useState(false);
  const FormComponent = formComponent;

  const arrayOfTags = useMemo(() => parseFilterToArrayOfTagsData(filter), [filter]);

  const handleClearFilter = useCallback(
    (filter: T, filterValue: TagItem, field: string) => {
      onUpdateFilter(clearFilter(filter, filterValue, field));
    },
    [filter]
  );

  const visible: TagItem[] = arrayOfTags.slice(0, VISIBLE_COUNT);

  const hidden: TagItem[] = arrayOfTags.slice(VISIBLE_COUNT);

  return (
    <div className={cn('flex flex-row items-center', className)}>
      {visible &&
        visible.map((item) => (
          <Tag
            key={item.value.id}
            tag={item}
            onRemoveTag={(item) => handleClearFilter(filter, item, item.value.type)}
          />
        ))}

      {hidden && hidden.length > 0 && (
        <Popover>
          <PopoverTrigger asChild>
            <span className="my-0.5 mr-1 inline-flex h-7 cursor-pointer items-center whitespace-nowrap rounded-sm bg-blue-50 px-2 text-sm text-primary transition-all duration-200 hover:bg-blue-100 hover:shadow-sm">
              {hidden.length} {t('Common.more')}
            </span>
          </PopoverTrigger>
          <PopoverContent className="w-80">
            <div className="flex flex-row flex-wrap gap-2">
              {hidden.map((item) => (
                <Tag
                  key={item.value.id}
                  tag={item}
                  onRemoveTag={(item) => handleClearFilter(filter, item, item.value.type)}
                />
              ))}
            </div>
          </PopoverContent>
        </Popover>
      )}

      <Popover open={filterOpen} onOpenChange={setFilterOpen}>
        <PopoverTrigger asChild>
          <Button className="mx-2" type="button" variant="ghost" size="icon">
            <ListFilter className="h-6 min-w-6" />
          </Button>
        </PopoverTrigger>
        <PopoverContent closeByAction withoutPortal align="end" className="w-80">
          <h2 className="mb-4 text-xl font-semibold">{t('Common.addFilter')}</h2>

          <FormComponent
            value={filter}
            onCancel={() => setFilterOpen(false)}
            onApply={(value) => {
              setFilterOpen(false);
              onUpdateFilter(value);
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
