import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

interface Request {
  progressCallBack?: (progressEvent: ProgressEvent) => void;
  file: File;
}

export type Options = Omit<
  UseMutationOptions<AxiosResponse<{ fileId: string }>, DefaultError, Request>,
  'mutationKey' | 'mutationFn'
>;

export const useOCRParseMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async ({ file, progressCallBack }: Request): Promise<AxiosResponse<{ fileId: string }>> => {
    let fd = new FormData();
    fd.append('file', file);
    const axiosResponse = await axiosInterceptorInstance.post(`ocr/parse`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
      },
      onUploadProgress: (progressEvent: any) => {
        progressCallBack && progressCallBack(progressEvent);
      },
    });
    return axiosResponse;
  };

  return useMutation({
    mutationKey: ['ocr', 'parse'],
    onSettled: ((data, error) => {
      if (!error) { 
        queryClient.resetQueries({ queryKey: ['long-polling', data?.data.fileId] });
      }
    }),
    mutationFn: _mutation,
    ...options,
  });
};
