import { Dialog } from '@/components/ui';
import { Download } from 'lucide-react';

import Image from 'next/image';
import { DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';

interface MessageImgContainerProps {
  message: any;
  onDownload: () => void;
}

export function MessageImgContainer({ message, onDownload }: MessageImgContainerProps) {
  return (
    <div className="flex w-full min-w-[320px] max-w-[500px] flex-col rounded-e-xl rounded-es-xl border-gray-200 bg-gray-100 p-4">
      <p className="text-sm font-normal text-gray-900 dark:text-white">{message.message}</p>
      <div className="group my-2.5">
        {/* Preview Image */}
        <Dialog>
          <DialogTrigger asChild>
            <Image
              sizes="(max-width: 320px) 400px"
              width={320}
              height={320}
              alt={message.name}
              src={message.url}
              className="cursor-pointer rounded-lg"
            />
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px] min-w-64">
            <DialogHeader>
              <DialogTitle>
                <Download
                  onClick={onDownload}
                  className="absolute left-4 top-4 size-4 cursor-pointer rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
                />
              </DialogTitle>
            </DialogHeader>
            <Image width={0} height={0} sizes="100vw" alt={message.name} src={message.url} className="h-auto w-full" />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
