import { Pagination, ScrollArea, toast, ToastTypeEnums, SimpleSearch } from '@/components/common';
import { Checkbox, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@/components/form';
import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useAdminsQuery } from '@/hooks/fetchers/queries/admins/useAdminsQuery';
import { useTranslations } from 'next-intl';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useCreateDepartmentMutation } from '@/hooks/fetchers/mutations/departments/useCreateDepartmentMutation';
import { useDepartmentQuery } from '@/hooks/fetchers/queries/departments/useDepartmentQuery';
import { useUpdateDepartmentMutation } from '@/hooks/fetchers/mutations/departments/useUpdateDepartmentMutation';
import { compileFullName } from '@/lib/utils';

const FormSchema = z.object({
  title: z.string({ message: 'Forms.title.required' }).min(1, { message: 'Forms.title.required' }),
});

const PER_PAGE = 100;

interface Props {
  onRefresh: () => void;
}

export type ManageDepartmentMembersDialogRef = {
  open: (departmentId?: string) => void;
};

const ManageDepartmentMembersDialog = forwardRef<ManageDepartmentMembersDialogRef, Props>(({ onRefresh }, ref) => {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [departmentId, setDepartmentId] = useState<string>();
  const [selected, setSelected] = useState<string[]>([]);
  const [keywordAdmin, setKeywordAdmin] = useState('');

  const {
    data: departmentDetails,
    isFetched,
    isRefetching,
    refetch,
  } = useDepartmentQuery(departmentId, { enabled: false, refetchOnWindowFocus: isOpen });

  const [paginationAdmin, setPaginationAdmin] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });

  const { mutate: create, isPending: isCreating } = useCreateDepartmentMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.departmentCreated'),
      });
      form.reset();
      onRefresh();
      setIsOpen(false);
    },
  });

  const { mutate: update, isPending: isUpdating } = useUpdateDepartmentMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.departmentUpdated'),
      });
      onRefresh();
      setIsOpen(false);
    },
  });

  useEffect(() => {
    if (departmentDetails && departmentDetails.users) {
      setSelected(departmentDetails.users.map((u) => u.id));
      form.setValue('title', departmentDetails.name);
    }
  }, [isFetched, isRefetching]);

  useEffect(() => {
    refetch();
    form.clearErrors();
  }, [isOpen]);

  useImperativeHandle(ref, () => ({
    open: (departmentId) => {
      setIsOpen(true);
      if (departmentId) {
        setDepartmentId(departmentId);
      } else {
        setDepartmentId(undefined);
        setSelected([]);
        form.reset();
      }
    },
  }));

  const form = useForm<z.infer<typeof FormSchema>>({
    mode: 'onTouched',
    resolver: zodResolver(FormSchema),
    defaultValues: {
      title: departmentDetails?.name ?? '',
    },
  });

  function onSubmitHandle() {
    const value = form.getValues();
    const data = {
      name: value.title,
      userList: selected,
    };
    if (value.title.trim().length !== 0) {
      if (departmentId) {
        update({
          id: departmentId,
          value: data,
        });
      } else {
        create(data);
      }
    } else {
      form.trigger();
    }
  }

  const { data: admins } = useAdminsQuery(
    {
      search: keywordAdmin,
      skip: paginationAdmin.page * paginationAdmin.perPage,
      take: paginationAdmin.perPage,
    },
    { enabled: true, refetchOnWindowFocus: isOpen }
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="min-w-[600px]">
        <DialogHeader>
          <DialogTitle>
            {departmentId ? t('Pages.Chats.editDepartment') : t('Pages.Chats.createDepartment')}
          </DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        <div className="h-[calc(100vh-346px)]">
          <Form {...form}>
            <form className="relative mb-4 w-full border-b border-gray-200 pb-8">
              <FormField
                control={form.control}
                name="title"
                render={({ field, fieldState }) => (
                  <FormItem className="w-full">
                    <FormLabel isRequired>{t('Forms.title.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="text"
                        placeholder={t('Forms.title.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </form>
          </Form>

          <SimpleSearch className="w-full" onSearchChange={(e) => setKeywordAdmin(e.target.value)} />
          <ul className="mt-6">
            <ScrollArea className="h-[calc(100vh-568px)]">
              {admins?.data.map((user) => (
                <li key={user.id} className="flex min-h-12 items-center border-b px-3">
                  <Checkbox
                    aria-label="Select row"
                    className="translate-y-[2px]"
                    checked={selected.includes(user.id)}
                    onCheckedChange={(v) => {
                      if (v) {
                        setSelected((prev) => [...prev, user.id]);
                      } else {
                        setSelected((prev) => prev.filter((selected) => selected !== user.id));
                      }
                    }}
                  />
                  <input hidden type="checkbox" value={user.id} />
                  <span className="ml-4 pt-1 text-base text-gray-700">{compileFullName(user)}</span>
                </li>
              ))}
            </ScrollArea>
          </ul>

          {admins && admins.totalCount > 0 && (
            <Pagination
              className="mt-4"
              changeCurrentPage={(page) =>
                setPaginationAdmin((prev) => ({
                  ...prev,
                  page: page - 1,
                }))
              }
              totalSize={admins && admins.totalCount ? admins.totalCount : 0}
              sizePerPage={paginationAdmin.perPage}
              currentPage={paginationAdmin.page + 1}
            />
          )}
        </div>
        <DialogFooter className="mt-4 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
          <Button onClick={() => setIsOpen(false)} variant="ghost" type="button" size="lg" className="mt-5">
            {t('Buttons.cancel')}
          </Button>
          <Button disabled={isCreating || isUpdating} type="button" size="lg" className="mt-5" onClick={onSubmitHandle}>
            {t('Buttons.save')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
});

ManageDepartmentMembersDialog.displayName = 'ManageDepartmentMembersDialog';

export { ManageDepartmentMembersDialog };
