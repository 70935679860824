'use client';
import { Button } from '@/components/ui';
import { DialogFooter } from '@/components/ui/dialog';
import {
  compileFullName,
  convertHoursToTime,
  getColorForServiceRole,
  openAddressInGoogleMaps,
  transformApiDateToViewDate,
  transformDateToViewDate,
} from '@/lib/utils';
import { useTranslations } from 'next-intl';
import { StatisticBadge, Chips, LabelValueItem, ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import { MyClient } from '../interfaces/my-clients.interface';
import { Colors } from '@/common/enums';
import { PreviewClientDialog, PreviewClientDialogRef } from '@/views/all-clients/preview/preview-client.dialog';
import { createRef } from 'react';
import { PreviewGuardianDialog, PreviewGuardianDialogRef } from '@/views/all-guardians/preview/preview-guardian.dialog';
import { DeclineServiceDialog, DeclineServiceDialogRef } from './decline-service.dialog';
import { useRequestAuthorizationMutation } from '@/hooks/fetchers/mutations/services/useRequestAuthorizationMutation';
import { useRequestExtensionMutation } from '@/hooks/fetchers/mutations/services/useRequestExtensionMutation';
import { useIsAllowed } from '@/lib/RBAC';
import { ManageShiftDialog, ManageShiftDialogRef } from '@/views/all-shifts/manage/manage-shift.dialog';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import dayjs from '@/lib/dayjsConfig';
import Calendar from './../../../../public/icons/calendar.svg';
import Clock from './../../../../public/icons/clock.svg';
import { SERVICES_STATUS_COLORS, USER_ROLES_COLORS, STATES_TYPES } from '@/common/constants';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';

interface Props {
  client?: MyClient;
  onDecline: () => void;
  onClose: () => void;
  onRefresh: () => void;
}

export function MyClientProfileTab({ client, onDecline, onClose, onRefresh }: Props) {
  const t = useTranslations();
  const previewClientDialogRef = createRef<PreviewClientDialogRef>();
  const previewGuardianDialogRef = createRef<PreviewGuardianDialogRef>();
  const declineServiceDialogRef = createRef<DeclineServiceDialogRef>();
  const manageShiftDialogRef = createRef<ManageShiftDialogRef>();
  const { checkPermissions } = useIsAllowed();

  const { mutate: requestAuthorization, isPending: isPendingRequestAuthorization } = useRequestAuthorizationMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        // description: t('Toasts.declineService'),
      });
      onRefresh();
    },
  });

  const { mutate: requestExtension, isPending: isPendingRequestExtension } = useRequestExtensionMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        // description: t('Toasts.declineService'),
      });
      onRefresh();
      onClose();
    },
  });

  const previewClient = (id: string) => {
    previewClientDialogRef.current?.openById(id);
  };

  const previewGuardian = (id: string) => {
    previewGuardianDialogRef.current?.openById(id);
  };

  const declineService = (id: string) => {
    declineServiceDialogRef.current?.open(id);
  };

  return (
    <>
      <div>
        <div className="relative max-w-[1000px]">
          <div className="absolute right-4 top-0 flex flex-col space-y-2">
            <StatisticBadge
              svgPath={Calendar}
              label={t('Pages.AllServices.dateAdded')}
              value={client?.createdAt ? dayjs(client.createdAt).format('MM.DD.YYYY') : '---'}
            />
            <StatisticBadge
              svgPath={Calendar}
              label={t('Pages.AllServices.initialVisit')}
              value={client?.initialVisitDate ? dayjs(client.initialVisitDate).format('MM.DD.YYYY') : '---'}
            />
            <StatisticBadge
              svgPath={Clock}
              label={t('Pages.AllServices.hoursWorkedInMonth')}
              value={client?.hoursProvided ? `${convertHoursToTime(client.hoursProvided)}` : '---'}
            />
          </div>
          <ScrollArea className="h-[calc(100vh-364px)] overflow-auto">
            <h2 className="mb-2 mt-4 text-sm font-bold text-gray-950">{t('Common.personalInformation')}</h2>
            <div className="mb-6 flex w-full max-w-[676px] flex-col space-y-6">
              <LabelValueItem label={t('Forms.title.label')} value={client?.title} />
            </div>

            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              <LabelValueItem label={t('Forms.client.label')} withoutBorder>
                <Chips
                  onClickAction={() => (client && client.client ? previewClient(client.client.id) : null)}
                  color={Colors.indigo}
                  title={compileFullName(client?.client)}
                />
              </LabelValueItem>
              <LabelValueItem label={t('Forms.stage.label')} withoutBorder>
                <Chips
                  color={SERVICES_STATUS_COLORS[client?.status as STATES_TYPES]}
                  title={client?.status}
                />
              </LabelValueItem>
              <LabelValueItem label={t('Forms.serviceRole.label')}>
                <Chips
                  color={getColorForServiceRole(client?.serviceRole.title, client?.serviceRole.speciality.code)}
                  title={client?.serviceRole.title}
                />
              </LabelValueItem>
              <LabelValueItem label={t('Forms.frequency.label')} value={`${client?.hours}h/${client?.frequency}`} />
              <LabelValueItem
                label={t('Forms.authorizedTo.label')}
                value={transformDateToViewDate(client?.authorizedTo, false)}
              />
              <LabelValueItem
                label={t('Forms.authorizedFrom.label')}
                value={transformApiDateToViewDate(client?.authorizedFrom)}
              />
            </div>

            <div className="mt-6 flex w-full max-w-[676px] flex-col space-y-6">
              <LabelValueItem
                label={t('Forms.speakingLanguage.label')}
                value={
                  client && client.languages && client.languages.length > 0 ? client.languages.join(', ') : undefined
                }
              />
              <LabelValueItem label={t('Forms.guardian.label')}>
                <div className="space-x-2">
                  {client?.client &&
                    client?.client.guardians &&
                    client?.client.guardians.map((guardian) => (
                      <Chips
                        onClickAction={() => previewGuardian(guardian.id)}
                        key={guardian.id}
                        color={USER_ROLES_COLORS[ROLE_TYPES.Guardian]}
                        title={compileFullName(guardian)}
                      />
                    ))}
                </div>
              </LabelValueItem>

              <LabelValueItem
                label={t('Forms.address.label')}
                value={client?.address}
                onClickByValue={() => openAddressInGoogleMaps(client?.address)}
              />
            </div>
          </ScrollArea>
        </div>

        <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
          {!client?.deactivatedAt && (
            <>
              {STATES_TYPES.CONTACTING_PARENTS === client?.status ||
              STATES_TYPES.THERAPIST_ASSIGNED === client?.status ||
              STATES_TYPES.PENDING_APPROVAL === client?.status ? (
                <Button
                  type="button"
                  variant="outlineDestructive"
                  size="lg"
                  className="mt-5"
                  onClick={() => declineService(client.id)}
                >
                  {t('Buttons.declineService')}
                </Button>
              ) : null}

              {STATES_TYPES.CONTACTING_PARENTS === client?.status && !client?.isExtended ? (
                <Button
                  type="button"
                  variant="secondary"
                  size="lg"
                  className="mt-5"
                  disabled={isPendingRequestExtension}
                  onClick={() => requestExtension(client.id)}
                >
                  {t('Buttons.requestExtension')}
                </Button>
              ) : null}

              {STATES_TYPES.CONTACTING_PARENTS === client?.status &&
              checkPermissions([PERMISSIONS_TYPES.CREATE_UNPUBLISHED_SHIFT_FOR_CONTACTING_PARENTS]) ? (
                <Button
                  type="button"
                  size="lg"
                  variant={
                    !(client?.shifts && client?.shifts.length > 0 && STATES_TYPES.CONTACTING_PARENTS === client?.status)
                      ? 'default'
                      : 'secondary'
                  }
                  className="mt-5"
                  onClick={() => manageShiftDialogRef.current?.open(undefined, client)}
                >
                  {t('Buttons.scheduleShifts')}
                </Button>
              ) : null}

              {client?.shifts && client?.shifts.length > 0 && STATES_TYPES.CONTACTING_PARENTS === client?.status ? (
                <Button
                  type="button"
                  size="lg"
                  className="mt-5"
                  disabled={isPendingRequestAuthorization}
                  onClick={() => requestAuthorization(client.id)}
                >
                  {t('Buttons.requestAuthorization')}
                </Button>
              ) : null}
            </>
          )}
        </DialogFooter>
      </div>
      <PreviewClientDialog onUpdated={onRefresh} ref={previewClientDialogRef} />
      <DeclineServiceDialog onDecline={onDecline} ref={declineServiceDialogRef} />
      <PreviewGuardianDialog onUpdated={onRefresh} ref={previewGuardianDialogRef} />
      <ManageShiftDialog ref={manageShiftDialogRef} onUpdated={onRefresh} />
    </>
  );
}
