import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { Admin } from '@/views/admin-users-list/interfaces/admins.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';

export type Options = Omit<UseQueryOptions<unknown, DefaultError, Admin | null>, 'queryKey' | 'queryFn'>;

export const useAdminQuery = (id?: string, options?: Options) => {
  const _query = async (): Promise<Admin | null> => {
    if (id) {
      const { data } = await axiosInterceptorInstance.get(`admins/${id}`);
      return data;
    } else {
      return null;
    }
  };

  return useQuery({
    queryKey: ['admins', id],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
