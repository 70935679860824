'use client';
import Folder from './../../../public/icons/folder-2.svg';
import Calendar from './../../../public/icons/calendar.svg';
import CalendarYellow from './../../../public/icons/calendar-yellow.svg';
import DocumentRose from './../../../public/icons/document-rose.svg';
import UserSquareBlue from './../../../public/icons/user-square-blue.svg';
import BookSaved from './../../../public/icons/book-saved.svg';
import CalendarPurple from './../../../public/icons/calendar-purple.svg';
import UserSquareRose from './../../../public/icons/user-square-rose.svg';
import { useTranslations } from 'next-intl';
import { StatisticBadge } from '@/components/common';
import { NavItem } from './components/nav-item';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { useDashboardsQuery } from '@/hooks/fetchers/queries/dashboards/useDashboardsQuery';
import { useSession } from 'next-auth/react';
import { ROLE_CHANGE_REASONS } from '@/common/enums';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';
import { Info } from 'lucide-react';

export function DashboardTherapistView() {
  const t = useTranslations();
  const { checkPermissions, currentRole } = useIsAllowed();
  const { data: dashboardData } = useDashboardsQuery();
  const { data: session } = useSession();

  let limitReason;
  let limitType = 'Limited';
  if (
    dashboardData &&
    dashboardData.limitationData &&
    (currentRole === ROLE_TYPES['Assistant Limited'] || currentRole === ROLE_TYPES['Therapist Limited'])
  ) {
    switch (dashboardData.limitationData.reason) {
      case ROLE_CHANGE_REASONS.START_OF_PROBATION: // You have been limited from picking up new clients for 1 month because you have reserved your first 10 clients
        limitReason = t('Common.START_OF_PROBATION');
        limitType = 'On probation';
        break;
      case ROLE_CHANGE_REASONS.LIMITED_BY_ADMIN: // You have been limited from picking up new clients by an admin. Please, contact Compliance Specialist
        limitReason = t('Common.LIMITED_BY_ADMIN');
        limitType = 'Limited';
        break;
      case ROLE_CHANGE_REASONS.LOW_PARTICIPATION: // You have been limited from picking up new clients for 1 month because your no-show rate is over 40%
        limitReason = t('Common.LOW_PARTICIPATION');
        limitType = 'Participation';
        break;
      case ROLE_CHANGE_REASONS.POOR_SERVICE_QUALITY: // You have been limited from picking up new clients for 1 month because you have returned over 3 clients
        limitReason = t('Common.POOR_SERVICE_QUALITY');
        limitType = 'Quality';
        break;
      case ROLE_CHANGE_REASONS.PAST_DUE_REPORTS: // You have been limited from picking up new clients until you submit all of your past-due reports
        limitReason = t('Common.PAST_DUE_REPORTS');
        limitType = 'Reports';
        break;
      case ROLE_CHANGE_REASONS.OVERTIME:
        limitReason = t('Common.OVERTIME_PREVIEW');
        limitType = 'Overtime';
        break;
      default:
        limitReason = t('Common.LIMITED_BY_ADMIN');
        limitType = 'Limited';
        break;
    }
  }

  return (
    <main className="pl-4 pr-6">
      <div className="flex flex-row items-center">
        <h1 className="mt-2 py-4 text-2xl font-bold">
          {session?.user && t('Pages.Dashboard.title', { name: session.user.firstName })}
        </h1>
      </div>

      <div className="mb-6 grid grid-flow-col grid-cols-3 gap-4">
        {limitReason && (
          <div className="flex min-w-80 flex-row items-center rounded-md border border-red-200 bg-red-50 pl-1">
            <Info className="mx-2 size-6 rounded-full bg-red-500 text-white" />
            <div className="flex max-w-96 flex-col p-2">
              <span className="line-clamp-3 text-sm font-normal text-gray-500">
                {' '}
                <b>{limitType}.</b> {limitReason}
              </span>
            </div>
          </div>
        )}

        <StatisticBadge
          className="min-w-80"
          size="lg"
          iconHeight={54}
          iconWidth={54}
          classNameIcon="p-3 ml-3"
          svgPath={Folder}
          label={t('Pages.Dashboard.pastDueDocs')}
          value={dashboardData?.pastDueDocumentsCount ?? 0}
        />
        <StatisticBadge
          className="min-w-80"
          size="lg"
          iconHeight={54}
          iconWidth={54}
          classNameIcon="p-3 ml-3"
          svgPath={Calendar}
          label={t('Pages.Dashboard.hoursWorked')}
          value={
            dashboardData?.hoursProvided && dashboardData?.hoursPlanned
              ? `${Math.round((dashboardData?.hoursProvided / dashboardData?.hoursPlanned) * 100)}%`
              : '0%'
          }
        />
      </div>

      <div className="grid min-h-[72px] grid-cols-3 gap-4">
        {session && (
          <>
            <div className="col-span-2 grid grid-flow-col gap-4">
              {checkPermissions([PERMISSIONS_TYPES.AVAILABLE_CLIENTS_LIST]) && (
                <NavItem
                  path="/dashboard/available-clients"
                  className="bg-blue-50"
                  svgPath={UserSquareBlue}
                  label={t('Pages.Dashboard.availableClients')}
                />
              )}
              {checkPermissions([PERMISSIONS_TYPES.MY_CLIENTS_LIST]) && (
                <NavItem
                  path="/dashboard/my-clients"
                  className="bg-rose-100"
                  svgPath={UserSquareRose}
                  label={t('Pages.Dashboard.myClients')}
                />
              )}
              {checkPermissions([PERMISSIONS_TYPES.MY_SCHEDULE]) && (
                <NavItem
                  path="/dashboard/my-schedule"
                  className="bg-yellow-100"
                  svgPath={CalendarYellow}
                  label={t('Pages.Dashboard.mySchedule')}
                />
              )}
              {checkPermissions([PERMISSIONS_TYPES.VIEW_MY_DOCUMENTS]) && (
                <NavItem
                  path="/dashboard/my-documents"
                  className="bg-red-100"
                  svgPath={DocumentRose}
                  label={t('Pages.Dashboard.myDocuments')}
                />
              )}
              {checkPermissions([PERMISSIONS_TYPES.VIEW_MY_TIMESHEETS_LIST]) && (
                <NavItem
                  path="/dashboard/my-timesheets"
                  className="bg-green-100"
                  svgPath={BookSaved}
                  label={t('Buttons.timesheets')}
                />
              )}
              {checkPermissions([PERMISSIONS_TYPES.VIEW_MY_SHIFTS]) && (
                <NavItem
                  path="/dashboard/all-shifts"
                  className="bg-purple-100"
                  svgPath={CalendarPurple}
                  label={t('Buttons.signatures')}
                />
              )}
            </div>
          </>
        )}
      </div>
    </main>
  );
}
