import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

interface Request {
  name: string;
  userList: string[];
}

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useCreateDepartmentMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: Request): Promise<string> => {
    const { data } = await axiosInterceptorInstance.post(`departments`, value);
    queryClient.invalidateQueries({ queryKey: ['departments'] });
    queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
    return data;
  };

  return useMutation({
    mutationKey: ['departments'],
    mutationFn: _mutation,
    ...options,
  });
};
