import { Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import Folder from './../../../../public/icons/folder-blue.svg';
import Calendar from './../../../../public/icons/calendar-blue.svg';
import Wallet from './../../../../public/icons/wallet-money.svg';
import { useTranslations } from 'next-intl';
import { createRef, forwardRef, useImperativeHandle, useState } from 'react';
import { StatisticBadge } from '@/components/common';
import { Chips } from '@/components/common';
import {
  PreviewTherapistDialog,
  PreviewTherapistDialogRef,
} from '@/views/all-therapists/preview/preview-therapist.dialog';
import { DetailedCalculationTable } from '../table/detailed-calculation-table';
import { TimesheetPayroll } from '../interfaces/timesheet-payroll.interface';
import { compileFullName, minutesToTime } from '@/lib/utils';
import { USER_ROLES_COLORS } from '@/common/constants';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';

interface Props {
  period: string
}

export type DetailedCalculationDialogRef = {
  open: (timesheet: TimesheetPayroll) => void;
};

const DetailedCalculationDialog = forwardRef<DetailedCalculationDialogRef, Props>(({period}, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();
  const previewTherapistDialogRef = createRef<PreviewTherapistDialogRef>();
  const [timesheetData, setTimesheetData] = useState<TimesheetPayroll | null>(null);

  useImperativeHandle(ref, () => ({
    open: (value) => {
      setIsOpen(true);
      setTimesheetData(value);
    },
  }));

  const previewTherapist = (id: string) => {
    previewTherapistDialogRef.current?.openById(id);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-screen-xl">
          <DialogHeader>
            <DialogTitle className='flex flex-row items-center justify-start'>
              {t('Pages.Timesheets.previewTimesheet')}
              <div className="ml-4">
                {timesheetData && <Chips color={USER_ROLES_COLORS[ROLE_TYPES.Therapist]} 
                onClickAction={checkPermissions([PERMISSIONS_TYPES.PREVIEW_THERAPIST_PROFILE]) ? () => previewTherapist(timesheetData.therapist.id) : undefined}
                title={compileFullName(timesheetData?.therapist)} />}
              </div>
            </DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>

          <div className="h-[calc(100vh-180px)]">
            <div className="mb-6 flex flex-row space-x-4">
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Folder}
                label={t('Pages.Timesheets.hoursProvided')}
                value={timesheetData ? minutesToTime(timesheetData.totalDuration) : `---`} 
              />
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Wallet}
                label={t('Pages.Timesheets.totalPay')}
                value={timesheetData ? `$${timesheetData.totalPay.toFixed(2)}` : `---`}
              />
              {/* <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Folder}
                label={t('Pages.Timesheets.submittedReport')}
                value={timesheetData ? `${timesheetData.reportsCount}` : `---`}
              /> */}
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Calendar}
                label={t('Pages.Timesheets.period')}
                value={period}
              />
            </div>

            {timesheetData && <DetailedCalculationTable payroll={timesheetData} />}
          </div>
        </DialogContent>
      </Dialog>
      <PreviewTherapistDialog ref={previewTherapistDialogRef} />
    </>
  );
});

DetailedCalculationDialog.displayName = 'DetailedCalculationDialog';

export { DetailedCalculationDialog };
