export const ACTIVITY_ACTIONS = ['Create', 'Update', 'Soft delete', 'Restore'];
export const ACTIVITY_ENTITIES = [
  'users',
  'roles',
  'shifts',
  'clients',
  'devices',
  'services',
  'comments',
  'documents',
  'geofences',
  'locations',
  'user_roles',
  'departments',
  'permissions',
  'specialities',
  'service_roles',
  'service_shifts',
  'job_role_types',
  'therapist_rates',
  'client_guardians',
  'role_permissions',
  'guardian_services',
  'service_role_rates',
  'therapist_work_logs',
  'therapist_supervisors',
];
