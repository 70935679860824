export const ALLOWED_FILE_EXT = [
    'mpeg',
    'mpg',
    'mp4',
    'webm',
    'qt',
    'flv',
    '3gp',
    '3g2',
    'avi',
    'mov',
    'pdf',
    'doc',
    'docx',
    'potx',
    'ppsx',
    'ppt',
    'pptx',
    'rtf',
    'txt',
    'xls',
    'xlsx',
    'gif',
    'jpeg',
    'jpg',
    'png',
    // 'svg'
  ];