'use client';

import { useTranslations } from 'next-intl';

export function LoadingApiState() {
  const t = useTranslations();
  return (
    <div className="flex h-1/2 w-full flex-col items-center justify-center">
      <div
        className="inline-block size-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-blue-400 motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>

      <p className="mt-4 text-gray-500">{t('Common.waitPlease')}</p>
    </div>
  );
}
