import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { MySchedule } from '@/views/my-schedule/my-schedule.interface';

interface Params {
  firstDayOfTheWeekDate: string;
}

export type Options<T> = Omit<UseQueryOptions<unknown, DefaultError, T>, 'queryKey' | 'queryFn'>;

export const useMySchedulesQuery = <T = MySchedule[]>(params: Params, options?: Options<T>) => {
  const _query = async (): Promise<MySchedule[]> => {
    const { data } = await axiosInterceptorInstance.get('shifts/my-schedule/therapist', {
      params,
    });
    return data;
  };

  return useQuery({
    queryKey: ['shifts', 'my-schedule', 'therapist', params.firstDayOfTheWeekDate],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
