'use client';
import {
  ColumnOrderState,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { createRef, useEffect, useState } from 'react';
import { Pagination, CommonTable, ColumnAdvance, TableColumnSettings } from '@/components/common';
import { useTranslations } from 'next-intl';
import { useTimesheetPayrollQuery } from '@/hooks/fetchers/queries/timesheets/useTimesheetPayrollQuery';
import { TimesheetPayroll } from '../interfaces/timesheet-payroll.interface';
import { getDetailedCalculationColumns } from './detailed-calculation-columns';
import { TimesheetPayrollTherapist } from '../interfaces/timesheet-payroll-therapist.interface';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';
import { TABLE_TYPES } from '@/common/enums';
import { PreviewServiceDialog, PreviewServiceDialogRef } from '@/views/all-services/preview/preview-service.dialog';
import { detailedCalculationSortingHelper } from './detailed-calculation-sorting-helper';

const PER_PAGE = 100;

export function DetailedCalculationTable({ payroll }: { payroll: TimesheetPayroll }) {
  const t = useTranslations();
  const [columnVisibility, setColumnVisibility] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const previewServiceDialogRef = createRef<PreviewServiceDialogRef>();
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });

  const [data, setData] = useState<TimesheetPayrollTherapist[]>([]);

  const { data: tableConfig } = useTableSettingsQuery(TABLE_TYPES.DETAILED_CALCULATION);
  const { mutate: setTableConfig } = useTableSettingsMutation();
  const { data: tableData, isLoading } = useTimesheetPayrollQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    therapistId: payroll.therapistId,
    id: payroll.timesheetId,
    ...detailedCalculationSortingHelper(sorting),
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<TimesheetPayrollTherapist>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);

  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  const handlePreviewService = (serviceId: string) => {
    previewServiceDialogRef.current?.openById(serviceId);
  };

  const table = useReactTable({
    columns: getDetailedCalculationColumns({ t, onPreviewService: handlePreviewService }),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
  });

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<TimesheetPayrollTherapist>[]) => {
    setTableConfig({
      name: TABLE_TYPES.DETAILED_CALCULATION,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<TimesheetPayrollTherapist>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  return (
    <>
      <header className="flex flex-row items-center justify-end py-2">
        <div className="flex flex-row items-center space-x-2">
          <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />
        </div>
      </header>

      <div className="relative h-[calc(100vh-368px)] max-w-full overflow-auto">
        <div className="absolute w-full">
          <CommonTable table={table} />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          className="mt-1"
          changeCurrentPage={(page) =>
            setPagination((prev) => {
              return {
                ...prev,
                page: page - 1,
              };
            })
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      {isLoading && (
        <div className="flex h-9 w-full items-center justify-center">
          <span className="mt-1 text-sm text-gray-400">{t('Common.loadingWait')}</span>
        </div>
      )}

      <PreviewServiceDialog ref={previewServiceDialogRef} />
    </>
  );
}
