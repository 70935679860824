import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { TagItem } from '@/components/form';
import { AuditLog } from '@/views/audit-log/audit-log.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as _ from 'lodash';

type Response = {
  data: AuditLog[];
  totalCount: number;
};
interface Params {
  skip: number;
  take: number;
  user?: TagItem[] | null;
  action?: string;
  search?: string;
  entity?: string;
  dateRange?: TagItem[] | null;
}

export type Options<T> = Omit<UseQueryOptions<unknown, DefaultError, T>, 'queryKey' | 'queryFn'>;

export const useActivityLogsQuery = <T = Response>(params: Params, options?: Options<T>) => {

  const queryParams = {
    skip: params.skip,
    take: params.take,
    userId: params.user && params.user[0] ? params.user[0].value.id : null,
    action: params.action,
    search: params.search,
    entity: params.entity,
    dateFrom: params.dateRange && params.dateRange[0] ? params.dateRange[0].value.from : null,
    dateTo: params.dateRange && params.dateRange[0] ? params.dateRange[0].value.to : null,
  };

  const cleanedQueryParams = _.omitBy(queryParams, value => _.isNil(value) || value === '');

  const _query = async (): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.get('activity-logs', {
      params: cleanedQueryParams
    });
    return data;
  };

  return useQuery({
    queryKey: ['activity-logs', queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
