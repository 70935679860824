import { sortOrderValueToAPIParams } from '@/components/common';
import { SortingState } from '@tanstack/react-table';

export const availableClientsSortingHelper = (
  sorting: SortingState
): {
  sortField: string | null;
  sortOrder: 'ASC' | 'DESC';
} | null => {
  let sortField = null;
  const preParsing = sortOrderValueToAPIParams(sorting);

  if (preParsing) {
    switch (preParsing.id) {
      case 'Title':
        sortField = 'title';
        break;
    }
    return {
      sortField,
      sortOrder: preParsing.sortOrder,
    };
  }
  return null;
};
