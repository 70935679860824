import { Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import Folder from './../../../../public/icons/folder-blue.svg';
import Calendar from './../../../../public/icons/calendar-blue.svg';
import Wallet from './../../../../public/icons/wallet-money.svg';
import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { StatisticBadge } from '@/components/common';

import { minutesToTime } from '@/lib/utils';
import { MyDetailedCalculationTable } from '../table/my-detailed-calculation-table';
import { Timesheet } from '@/views/timesheets/interfaces/timesheet.interface';
import dayjs from 'dayjs';

export type MyDetailedCalculationDialogRef = {
  open: (timesheet: Timesheet) => void;
};

const MyDetailedCalculationDialog = forwardRef<MyDetailedCalculationDialogRef>(({}, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const [timesheetData, setTimesheetData] = useState<Timesheet | null>(null);

  useImperativeHandle(ref, () => ({
    open: (value) => {
      setIsOpen(true);
      setTimesheetData(value);
    },
  }));

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-screen-xl">
          <DialogHeader>
            <DialogTitle className="flex flex-row items-center justify-start">
              {t('Pages.MyTimesheets.previewTimesheet')}
            </DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>

          <div className="h-[calc(100vh-180px)]">
            <div className="mb-6 flex flex-row space-x-4">
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Folder}
                label={t('Pages.Timesheets.hoursProvided')}
                value={timesheetData ? minutesToTime(timesheetData.report.totalDuration) : `---`}
              />
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Wallet}
                label={t('Pages.Timesheets.totalPay')}
                value={timesheetData ? `$${timesheetData.report.totalPay.toFixed(2)}` : `---`}
              />
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Calendar}
                label={t('Pages.Timesheets.period')}
                value={
                  timesheetData
                    ? `${dayjs(timesheetData.periodFrom).format('MMM')} ${dayjs(timesheetData.periodFrom).format('DD')} - ${dayjs(timesheetData.periodTo).format('MMM')} ${dayjs(timesheetData.periodTo).format('DD')}`
                    : '---'
                }
              />
            </div>

            {timesheetData && <MyDetailedCalculationTable timesheet={timesheetData} />}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
});

MyDetailedCalculationDialog.displayName = 'MyDetailedCalculationDialog';

export { MyDetailedCalculationDialog };
