'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  GooglePlacesInput,
  PhoneInput,
  TagsSelector,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn, compileFullName, transformDateToViewDate } from '@/lib/utils';
import { Button } from '@/components/ui';
import { Chips, LabelValueItem, ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import { useMask } from '@react-input/mask';
import { Client, ParsedClientFromPDF } from '../interfaces/clients.interface';
import { DialogFooter } from '@/components/ui/dialog';
import { DATE_MASK, LANGUAGES, LANGUAGES_TAGS, REGIONAL_CENTERS, RELATIONSHIPS, USER_ROLES_COLORS, PHONE_COUNTRIES } from '@/common/constants';
import {
  createClientAndGuardianValidationSchema,
  CreateClientAndGuardianValidationSchema,
} from './create-client-and-guardian-validation-schema';
import { TagItem } from '@/components/form';
import { useGuardianExistQuery } from '@/hooks/fetchers/queries/guardians/useGuardianExistQuery';
import { useEffect } from 'react';
import { OctagonAlert } from 'lucide-react';
import { useCreateOCRClientMutation } from '@/hooks/fetchers/mutations/ocr/useCreateOCRClientMutation';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';

const LANGUAGES_TAGS_ITEMS = LANGUAGES_TAGS();

interface Props {
  fileId: string;
  client?: ParsedClientFromPDF;
  onCancel: () => void;
  onCreate: (client: Client) => void;
}

export function CreateClientAndGuardianForm({ client, fileId, onCancel, onCreate }: Props) {
  const t = useTranslations();
  const inputDateRef = useMask({ ...DATE_MASK });

  const { mutate: create, isPending: isCreating } = useCreateOCRClientMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.clientCreated'),
      });
      form.reset();
      onCreate(data);
    },
  });

  const { refetch: isGuardianExist, data: existGuardian } = useGuardianExistQuery(
    {
      phoneNumber: client?.phoneNumber
      ? client?.phoneNumber.charAt(0) === '1'
        ? client?.phoneNumber
        : '1' + client?.phoneNumber
      : '',
      email: client?.email
    },
    { enabled: false }
  );

  useEffect(() => {
    isGuardianExist();
  }, [client]);

  const _language = client?.language ? client?.language.charAt(0) + client?.language.slice(1).toLowerCase() : null;
  const _relationship = client?.userRelationship ? client?.userRelationship.charAt(0) + client?.userRelationship.slice(1).toLowerCase() : null;

  const form = useForm<CreateClientAndGuardianValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(createClientAndGuardianValidationSchema),
    defaultValues: {
      firstName: client?.clientFirstName ?? '',
      lastName: client?.clientLastName ?? '',
      caseManager: client?.caseManager ?? '',
      address: client?.address ?? '',
      uci: client?.uci ? client.uci.toString() : '',
      dob: client?.dob ? transformDateToViewDate(client.dob) : '',
      firstNameGuardian: client?.userFirstName ?? '',
      lastNameGuardian: client?.userLastName ?? '',
      regionalCenter: client?.medicalCenterMapped && REGIONAL_CENTERS.includes(client?.medicalCenterMapped) ? client?.medicalCenterMapped : '',
      email: client?.email ?? '',
      primaryNumber: client?.phoneNumber
        ? client?.phoneNumber.charAt(0) === '1'
          ? client?.phoneNumber
          : '1' + client?.phoneNumber
        : '',
      primaryRelationship: _relationship && RELATIONSHIPS.includes(_relationship) ? _relationship : '',
      languages: _language && LANGUAGES.includes(_language) ? [{ label: _language, value: { id: _language } }] : [],
    },
  });

  const onSubmit: SubmitHandler<CreateClientAndGuardianValidationSchema> = async (
    value: CreateClientAndGuardianValidationSchema
  ) => {
    create({
      ...value,
      fileId,
      guardianId: existGuardian ? existGuardian.id : ''
    });
  };


  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <ScrollArea className="h-[calc(100vh-316px)] overflow-auto">
          <h3 className="mb-4 text-base font-semibold text-gray-900">{t('Pages.AllClients.clientDetails')}</h3>
          <div className="grid grid-cols-form-cols-2 gap-8 ml-1">
            <div className="relative max-w-80">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.firstName.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        placeholder={t('Forms.firstName.placeholder')}
                        {...field}
                        type="text"
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative max-w-80">
              <FormField
                control={form.control}
                name="lastName"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.lastName.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="text"
                        placeholder={t('Forms.lastName.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative max-w-80">
              <FormField
                control={form.control}
                name="caseManager"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.caseManager.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        placeholder={t('Forms.caseManager.placeholder')}
                        {...field}
                        type="text"
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative max-w-80">
              <FormField
                control={form.control}
                name="uci"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.uciNumber.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="text"
                        placeholder={t('Forms.uciNumber.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative max-w-80">
              <FormField
                control={form.control}
                name="dob"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.dateOfBirth.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        ref={inputDateRef}
                        type="text"
                        placeholder={t('Forms.dateOfBirth.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative max-w-80">
              <FormField
                control={form.control}
                name="address"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.address.label')}</FormLabel>
                    <FormControl>
                      <GooglePlacesInput
                        values={field.value}
                        onChanges={field.onChange}
                        className={cn('', !!fieldState.error && 'border-destructive')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative max-w-80">
              <FormField
                control={form.control}
                name="regionalCenter"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.regionalCenter.label')}</FormLabel>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue placeholder={t('Forms.regionalCenter.placeholderSelector')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {REGIONAL_CENTERS.map((rc) => (
                            <SelectItem key={rc} value={rc}>
                              {rc}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <h3 className="my-4 mt-5 text-base font-semibold text-gray-900">{t('Pages.AllClients.guardianInformation')}</h3>

          {existGuardian && (
            <>
              <div className="flex max-w-[400px] flex-row items-start justify-start rounded-sm border border-orange-300 bg-orange-50 px-3 py-2 mb-4">
                <OctagonAlert className="mr-4 size-7 text-orange-400" />
                <span className="text-base font-light text-orange-600">
                  {t('Pages.AllClients.alreadyExistGuardian')}
                </span>
              </div>

              <LabelValueItem withoutBorder label={t('Forms.guardian.label')}>
                <div className="space-x-2 space-y-2">
                  <Chips color={USER_ROLES_COLORS[ROLE_TYPES.Guardian]} title={compileFullName(existGuardian)} />
                </div>
              </LabelValueItem>
            </>
          )}

          {!existGuardian && (
            <>
              <div className="grid grid-cols-form-cols-2 gap-8">
                <div className="relative max-w-80">
                  <FormField
                    control={form.control}
                    name="firstNameGuardian"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel isRequired>{t('Forms.firstName.label')}</FormLabel>
                        <FormControl>
                          <Input
                            hasError={!!fieldState.error}
                            className="w-full"
                            placeholder={t('Forms.firstName.placeholder')}
                            {...field}
                            type="text"
                          />
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="relative max-w-80">
                  <FormField
                    control={form.control}
                    name="lastNameGuardian"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel isRequired>{t('Forms.lastName.label')}</FormLabel>
                        <FormControl>
                          <Input
                            hasError={!!fieldState.error}
                            className="w-full"
                            {...field}
                            type="text"
                            placeholder={t('Forms.lastName.placeholder')}
                          />
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="relative max-w-80">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>{t('Forms.email.label')}</FormLabel>
                        <FormControl>
                          <Input
                            hasError={!!fieldState.error}
                            className="w-full"
                            placeholder={t('Forms.email.placeholder')}
                            {...field}
                            type="text"
                          />
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="relative max-w-80">
                  <FormField
                    control={form.control}
                    name="primaryNumber"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel isRequired>{t('Forms.primaryNumber.label')}</FormLabel>
                        <FormControl>
                          <PhoneInput
                            countries={PHONE_COUNTRIES}
                            placeholder={t('Forms.phoneNumber.placeholder')}
                            {...field}
                            className="w-full"
                            hasError={!!fieldState.error}
                          />
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="relative max-w-80">
                  <FormField
                    control={form.control}
                    name="languages"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel isRequired>{t('Forms.languages.label')}</FormLabel>
                        <FormControl>
                          <TagsSelector
                            data={LANGUAGES_TAGS_ITEMS}
                            values={(field.value ? field.value : []) as TagItem[]}
                            onChoose={field.onChange}
                            placeholder={t('Forms.languages.label')}
                            className={cn('', !!fieldState.error && 'border-destructive')}
                          />
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="relative max-w-80">
                  <FormField
                    control={form.control}
                    name="primaryRelationship"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>{t('Forms.primaryRelationship.label')}</FormLabel>
                        <FormControl>
                          <Select value={field.value} onValueChange={field.onChange}>
                            <FormControl>
                              <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                                <SelectValue placeholder={t('Forms.primaryRelationship.placeholder')} />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value={null as any}>None</SelectItem>
                              {RELATIONSHIPS.map((value) => (
                                <SelectItem key={value} value={value}>
                                  {value}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <h3 className="mb-2 mt-7 text-base font-semibold text-gray-900">{t('Pages.AllClients.additionalDetails')}</h3>

              <div className="grid grid-cols-form-cols-2 gap-8">
                <div className="relative max-w-80">
                  <FormField
                    control={form.control}
                    name="secondaryNumber"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>{t('Forms.secondaryNumber.label')}</FormLabel>
                        <FormControl>
                          <PhoneInput
                            countries={PHONE_COUNTRIES}
                            placeholder={t('Forms.secondaryNumber.placeholder')}
                            {...field}
                            className="w-full"
                            hasError={!!fieldState.error}
                          />
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="relative max-w-80">
                  <FormField
                    control={form.control}
                    name="secondaryRelationship"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>{t('Forms.secondaryRelationship.label')}</FormLabel>
                        <FormControl>
                          <Select
                            value={field.value}
                            onValueChange={(e) => {
                              field.onChange(e);
                            }}
                          >
                            <FormControl>
                              <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                                <SelectValue placeholder={t('Forms.secondaryRelationship.placeholder')} />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value={null as any}>None</SelectItem>
                              {RELATIONSHIPS.map((value) => (
                                <SelectItem key={value} value={value}>
                                  {value}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </>
          )}
        </ScrollArea>

        <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
          <Button onClick={onCancel} variant="ghost" type="button" size="lg" className="mt-5">
            {t('Buttons.cancel')}
          </Button>

          <Button type="submit" size="lg" className="mt-5" disabled={isCreating}>
            {t('Buttons.save')}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
