import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { DialogDescription } from '@radix-ui/react-dialog';
import {
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  PhoneInput,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { manageAdminValidationSchema, ManageAdminValidationSchema } from './manage-admin-validation-schema';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { cn } from '@/lib/utils';
import { toast, ToastTypeEnums } from '@/components/common';
import { Admin } from './interfaces/admins.interface';
import { RoleLevels, useRolesQuery } from '@/hooks/fetchers/queries/useRolesQuery';
import { useUpdateAdminMutation } from '@/hooks/fetchers/mutations/admins/useUpdateAdminMutation';
import { PHONE_COUNTRIES } from '@/common/constants';

interface Props {
  onUpdated: () => void;
  onDelete: (id: string) => void;
}

export type ManageAdminDialogRef = {
  open: (account: Admin) => void;
};

const ManageAdminDialog = forwardRef<ManageAdminDialogRef, Props>(({ onUpdated, onDelete }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const accountRef = useRef<Admin | null>(null);

  const form = useForm<ManageAdminValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(manageAdminValidationSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      role: '',
    },
  });

  useImperativeHandle(ref, () => ({
    open: (account) => {
      setIsOpen(true);
      accountRef.current = account;
      form.setValue('firstName', account.firstName);
      form.setValue('lastName', account.lastName);
      form.setValue('email', account.email);
      form.setValue('phoneNumber', account.phone);
      form.setValue('role', account.role);
    },
  }));

  const {
    data: roles,
    isLoading: isLoadingRolesIds,
    refetch: fetchRoles,
  } = useRolesQuery(RoleLevels.ADMIN, {
    enabled: false,
    refetchOnWindowFocus: isOpen
  });

  const { mutate: update, isPending } = useUpdateAdminMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: data,
      });
      setIsOpen(false);
      onUpdated();
    },
  });

  useEffect(() => {
    if (isOpen) {
      fetchRoles();
    }
  }, [isOpen]);

  const handleDeleteAccount = () => {
    if (accountRef.current && accountRef.current.id) {
      setIsOpen(false);
      onDelete(accountRef.current.id);
    }
  };

  const onSubmit: SubmitHandler<ManageAdminValidationSchema> = async (value: ManageAdminValidationSchema) => {
    const phone = value.phoneNumber ? value.phoneNumber.replace('+', '') : '';
    value.phoneNumber = phone === '' ? null : phone;
    update({
      id: accountRef.current?.id as string,
      value,
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className='w-full max-w-screen-xl'>
        <DialogHeader>
          <DialogTitle>{t('Pages.AdminUsersList.editProfile')}</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
            <div className="h-[calc(100vh-300px)]">
              <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
                <div className="relative">
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel isRequired>{t('Forms.firstName.label')}</FormLabel>
                        <FormControl>
                          <Input
                            hasError={!!fieldState.error}
                            className="w-full"
                            placeholder={t('Forms.firstName.placeholder')}
                            {...field}
                            type="text"
                          />
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="relative">
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel isRequired>{t('Forms.lastName.label')}</FormLabel>
                        <FormControl>
                          <Input
                            hasError={!!fieldState.error}
                            className="w-full"
                            {...field}
                            type="text"
                            placeholder={t('Forms.lastName.placeholder')}
                          />
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="relative">
                  <FormField
                    control={form.control}
                    name="role"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel isRequired>{t('Forms.accessLevel.label')}</FormLabel>
                        <FormControl>
                          <Select value={field.value} onValueChange={field.onChange}>
                            <FormControl>
                              <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                                <SelectValue
                                  placeholder={
                                    isLoadingRolesIds ? t('Common.loadingWait') : t('Forms.accessLevel.placeholder')
                                  }
                                />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {roles?.map((role) => (
                                <SelectItem key={role.id} value={role.name}>
                                  {role.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <h3 className="mb-5 mt-6 text-base font-semibold text-gray-900">{t('Common.contactInformation')}</h3>

              <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
                <div className="relative">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel isRequired>{t('Forms.email.label')}</FormLabel>
                        <FormControl>
                          <Input
                            hasError={!!fieldState.error}
                            className="w-full"
                            placeholder={t('Forms.email.placeholder')}
                            {...field}
                            type="text"
                          />
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="relative">
                  <FormField
                    control={form.control}
                    name="phoneNumber"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>{t('Forms.phoneNumber.label')}</FormLabel>
                        <FormControl>
                          <PhoneInput countries={PHONE_COUNTRIES} placeholder={t('Forms.phoneNumber.placeholder')} {...field} value={field.value ?? ''} className="w-full" hasError={!!fieldState.error} />
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>

            <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
              <Button
                onClick={handleDeleteAccount}
                variant="outlineDestructive"
                type="button"
                size="lg"
                className="mt-5"
              >
                {t('Buttons.deleteAdminAccount')}
              </Button>
              <Button type="submit" size="lg" className="mt-5" disabled={isPending}>
                {t('Buttons.save')}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
});

ManageAdminDialog.displayName = 'ManageAdminDialog';

export { ManageAdminDialog };
