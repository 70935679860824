'use client';
import { ColumnOrderState, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { createRef, useEffect, useState } from 'react';
import { Pagination, ColumnAdvance, TableColumnSettings, CommonTable } from '@/components/common';
import { getMyTimesheetsColumns } from './my-timesheets-columns';
import { useTranslations } from 'next-intl';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';
import { TABLE_TYPES } from '@/common/enums';
import { Timesheet } from '@/views/timesheets/interfaces/timesheet.interface';
import { useTimesheetsQuery } from '@/hooks/fetchers/queries/timesheets/useTimesheetsQuery';
import { MyDetailedCalculationDialog, MyDetailedCalculationDialogRef } from '../preview/my-detailed-calculation.dialog';

const PER_PAGE = 100;

export function MyTimesheetsTable() {
  const t = useTranslations();
  const [data, setData] = useState<Timesheet[]>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });
  const previewTimesheetDialogRef = createRef<MyDetailedCalculationDialogRef>();

  const { data: tableConfig } = useTableSettingsQuery(TABLE_TYPES.MY_TIMESHEETS);
  const { mutate: setTableConfig } = useTableSettingsMutation();
  const { data: tableData, isLoading } = useTimesheetsQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<Timesheet>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);

  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  const table = useReactTable({
    columns: getMyTimesheetsColumns({ t }),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      columnVisibility,
      columnOrder,
    },
  });

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<Timesheet>[]) => {
    setTableConfig({
      name: TABLE_TYPES.MY_TIMESHEETS,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<Timesheet>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  return (
    <>
      <header className="flex flex-row items-center justify-between">
        <h1 className="py-4 text-2xl font-bold">{t('Pages.MyTimesheets.title')}</h1>
        <div className="flex flex-row items-center space-x-2">
          <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />
        </div>
      </header>

      <div className="relative h-[calc(100vh-196px)] max-w-full overflow-auto">
        <div className="absolute w-full">
          <CommonTable
            table={table}
            onClickByRow={(rowOriginal) => previewTimesheetDialogRef.current?.open(rowOriginal)}
          />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          className="mt-1"
          changeCurrentPage={(page) =>
            setPagination((prev) => {
              return {
                ...prev,
                page: page - 1,
              };
            })
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      {isLoading && (
        <div className="flex h-9 w-full items-center justify-center">
          <span className="mt-1 text-sm text-gray-400">{t('Common.loadingWait')}</span>
        </div>
      )}
      <MyDetailedCalculationDialog ref={previewTimesheetDialogRef} />
    </>
  );
}
