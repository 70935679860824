'use client';
import { Input } from '@/components/form';
import { Button } from '@/components/ui';
import { cn } from '@/lib/utils';
import { ListFilter, Plus, SearchIcon, UserCog } from 'lucide-react';
import { ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { DropdownMenuSeparator } from '@radix-ui/react-dropdown-menu';
import { ChangeEvent, createRef, useEffect, useState } from 'react';
import { ChannelItem } from './channel-item';
import { SendbirdChatWith } from '@sendbird/chat';
import { GroupChannelModule, GroupChannel, Member } from '@sendbird/chat/groupChannel';
import { useTranslations } from 'next-intl';
import { ChooseMemberDialog, ChooseMemberDialogRef } from './choose-member.dialog';
import { ManageDepartmentsDialog, ManageDepartmentsDialogRef } from './manage-departments.dialog';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { DepartmentDetails } from '../interfaces/department.interface';
import _ from 'lodash';
interface ChannelListProps {
  className?: string;
  sb: SendbirdChatWith<GroupChannelModule[]>;
  channels: GroupChannel[];
  handleJoinChannel: (channelUrl: string) => void;
  handleJoinCreatedChannel: (channel: GroupChannel) => void;
}

export enum ChatTypes {
  DEPARTMENT = 'department',
  DIRECT = 'direct',
}

export function ChannelList({ className, sb, channels, handleJoinChannel, handleJoinCreatedChannel }: ChannelListProps) {
  const t = useTranslations();
  const [filter, setFilter] = useState<string>('all');
  const [filtered, setFiltered] = useState<GroupChannel[]>([]);
  const chooseMemberDialogRef = createRef<ChooseMemberDialogRef>();
  const manageDepartmentsDialogRef = createRef<ManageDepartmentsDialogRef>();
  const { checkPermissions } = useIsAllowed();

  const parseChannels = () => {
    return channels.map((ch) => {
      let chatTitleView = 'Chat';
      if (ch.name === '' && ch.memberCount === 2) {
        const interlocutor = ch.members.find((member) => member.userId !== sb.currentUser?.userId);
        if (interlocutor) {
          chatTitleView = interlocutor.nickname;
        }
      } else if (ch.name) {
        chatTitleView = ch.name;
      }
      ch.name = chatTitleView;
      return ch;
    });
  };

  useEffect(() => {
    const _channels = _.uniqBy(parseChannels(), 'url');
    setFiltered(_channels);
  }, [channels]);

  useEffect(() => {
    handleFilter();
  }, [filter]);

  const handleFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value || '';
    const regex = new RegExp(value, 'i');
    const _filtered = channels.filter((channel) => {
      return filter === 'all' ? regex.test(channel.name) : regex.test(channel.name) && channel.unreadMessageCount > 0;
    });
    setFiltered(_filtered);
  };

  const createChannel = async (
    channelName: string,
    userIdsToInvite: string[],
    customType: ChatTypes = ChatTypes.DIRECT,
    departmentId?: string
  ): Promise<[GroupChannel | null, any]> => {
    try {
      let groupChannelParams = {
        invitedUserIds: userIdsToInvite,
        name: channelName,
        operatorUserIds: userIdsToInvite,
        customType,
        data: departmentId ? JSON.stringify({ departmentId }) : '',
      };
      const groupChannel = await sb.groupChannel.createChannel(groupChannelParams);
      return [groupChannel, null];
    } catch (error) {
      return [null, error];
    }
  };

  const handleGoToChat = async (userId: string) => {
    const members: string[] = [sb.currentUser?.userId as string, userId];
    const directChats = channels.filter((channel: any) => channel.customType === ChatTypes.DIRECT);

    const existChat = directChats.find((channel: any) => {
      const membersIds = channel.members.map((member: Member) => member.userId);
      return membersIds.includes(userId);
    });

    if (!existChat) {
      const [groupChannel, error] = await createChannel('', members, ChatTypes.DIRECT);
      if (error) {
        console.log(`%c DEBUG LOG: ERROR CREATE CHAT`, 'color: yellow');
        console.error(error);
      }
      if (groupChannel) {
        handleJoinCreatedChannel(groupChannel);
      }
    } else {
      handleJoinChannel(existChat.url);
    }
  };

  const handleGoToGroupChat = async (department: DepartmentDetails) => {
    const departmentMembersIds = department.users.map((user) => user.id);
    if (departmentMembersIds.includes(sb.currentUser?.userId as string)) {
      toast({
        title: 'Warning',
        typeIcon: ToastTypeEnums.WARNING,
        description: t('Toasts.cannotCreateChatDepartmentExistAdmin'),
      });
    } else {
      const members: string[] = [sb.currentUser?.userId as string, ...departmentMembersIds];
      const departmentsChats = channels.filter((channel: any) => channel.customType === ChatTypes.DEPARTMENT);
      const existChat = departmentsChats.find((channel: any) => (channel.data.includes(department.id)));
  
      if (!existChat) {
        const [groupChannel, error] = await createChannel(`${department.name} (${sb.currentUser?.nickname})`, members, ChatTypes.DEPARTMENT, department.id);
        if (error) {
          console.log(`%c DEBUG LOG: ERROR CREATE CHAT`, 'color: yellow');
          console.error(error);
        }
        if (groupChannel) {
          await groupChannel.createMetaData({ departmentId: department.id });
          handleJoinCreatedChannel(groupChannel);
        }
      } else {
        await existChat.updateMetaData({ departmentId: department.id });
        handleJoinChannel(existChat.url);
      }
    }
  };

  return (
    <>
      <div className={cn('size-full max-w-80 border-r border-gray-300', className)}>
        <header className="flex h-14 flex-row items-center justify-between border-b border-gray-300 px-3 py-2">
          <h1 className="text-2xl font-bold">{t('Pages.Chats.chats')}</h1>
          <div>
            {checkPermissions([PERMISSIONS_TYPES.DEPARTMENTS_MANAGE]) && (
              <Button variant="ghost" size="icon" onClick={() => manageDepartmentsDialogRef.current?.open()}>
                <UserCog className="size-5" />
              </Button>
            )}
            <Button variant="ghost" size="icon" onClick={() => chooseMemberDialogRef.current?.open()}>
              <Plus />
            </Button>
          </div>
        </header>
        <form className="flex flex-row items-center justify-between border-b border-gray-300 px-3 py-2">
          <Input leftIcon={SearchIcon} onChange={handleFilter} placeholder={t('Forms.search.placeholder')}></Input>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button className="ml-2 min-w-9" type="button" variant="ghost" size="icon">
                <ListFilter />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>{t('Pages.Chats.filterByChats')}</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuCheckboxItem
                className="cursor-pointer"
                checked={filter === 'all'}
                onCheckedChange={() => {
                  setFilter('all');
                }}
              >
                {t('Pages.Chats.all')}
              </DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem
                checked={filter === 'unread'}
                onCheckedChange={() => setFilter('unread')}
                className="cursor-pointer"
              >
                {t('Pages.Chats.unread')}
              </DropdownMenuCheckboxItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </form>
        <ul>
          <ScrollArea className="h-[calc(100vh-178px)]">
            {filtered.map((channel: GroupChannel) => {
              return (
                <li key={channel.url} onClick={() => handleJoinChannel(channel.url)}>
                  <ChannelItem channel={channel} sb={sb} />
                </li>
              );
            })}
          </ScrollArea>
        </ul>
      </div>

      <ChooseMemberDialog
        sb={sb}
        handleCreate={handleGoToChat}
        handleCreateGroup={handleGoToGroupChat}
        ref={chooseMemberDialogRef}
      />
      {checkPermissions([PERMISSIONS_TYPES.DEPARTMENTS_MANAGE]) && (
        <ManageDepartmentsDialog ref={manageDepartmentsDialogRef} />
      )}
    </>
  );
}
