export enum ACTIVITY_ENTITY_TYPES {
    USERS = 'users',
    ROLES = 'roles',
    SHIFTS = 'shifts',
    CLIENTS = 'clients',
    DEVICES = 'devices',
    SERVICES = 'services',
    COMMENTS = 'comments',
    DOCUMENTS = 'documents',
    GEOFENCES = 'geofences',
    LOCATIONS = 'locations',
    USER_ROLES = 'user_roles',
    DEPARTMENTS = 'departments',
    PERMISSIONS = 'permissions',
    SPECIALITIES = 'specialities',
    SERVICE_ROLES = 'service_roles',
    SERVICE_SHIFTS = 'service_shifts',
    JOB_ROLE_TYPES = 'job_role_types',
    THERAPIST_RATES = 'therapist_rates',
    CLIENT_GUARDIANS = 'client_guardians',
    ROLE_PERMISSIONS = 'role_permissions',
    GUARDIAN_SERVICES = 'guardian_services',
    SERVICE_ROLE_RATES = 'service_role_rates',
    THERAPIST_WORK_LOGS = 'therapist_work_logs',
    THERAPIST_SUPERVISORS = 'therapist_supervisors',
  }