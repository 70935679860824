import { SimpleSearch, ConfirmDialog, Pagination, ScrollArea, toast, ToastAction, ToastTypeEnums } from '@/components/common';
import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Plus, Trash2 } from 'lucide-react';
import { useTranslations } from 'next-intl';
import { createRef, forwardRef, useImperativeHandle, useState } from 'react';
import { ManageDepartmentMembersDialog, ManageDepartmentMembersDialogRef } from './manage-department-members.dialog';
import { useRemoveDepartmentMutation } from '@/hooks/fetchers/mutations/departments/useRemoveDepartmentMutation';
import { useRemoveUndoDepartmentMutation } from '@/hooks/fetchers/mutations/departments/useRemoveUndoDepartmentMutation';
import { useDepartmentsQuery } from '@/hooks/fetchers/queries/departments/useDepartmentsQuery';

const PER_PAGE = 100;

export type ManageDepartmentsDialogRef = {
  open: () => void;
};

const ManageDepartmentsDialog = forwardRef<ManageDepartmentsDialogRef>(({}, ref) => {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [keyword, setKeywordAdmin] = useState('');
  const manageDepartmentMembersDialogRef = createRef<ManageDepartmentMembersDialogRef>();

  const [paginationDepartment, setPaginationDepartment] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });

  const { mutate: removeDepartment, isPending: isPendingRemove } = useRemoveDepartmentMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndoDepartment(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      refetchDepartments();
    },
  });

  const { mutate: removeUndoDepartment } = useRemoveUndoDepartmentMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      refetchDepartments();
    },
  });

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
  }));

  const { data: departments, refetch: refetchDepartments } = useDepartmentsQuery(
    {
      search: keyword,
      skip: paginationDepartment.page * paginationDepartment.perPage,
      take: paginationDepartment.perPage,
    },
    { enabled: true, refetchOnWindowFocus: isOpen }
  );

  const onRemoveHandle = (id: string) => {
    removeDepartment(id);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent closeByButtonOnly className="min-w-[600px]">
          <DialogHeader>
            <DialogTitle>{t('Pages.Chats.manageDepartments')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>

          <div className="h-[calc(100vh-228px)]">
            <div className="flex flex-row items-center justify-between pr-3">
              <SimpleSearch className="w-full" onSearchChange={(e) => setKeywordAdmin(e.target.value)} />
              <Button
                variant="ghost"
                size="icon"
                className="ml-3"
                onClick={() => manageDepartmentMembersDialogRef.current?.open()}
              >
                <Plus />
              </Button>
            </div>
            <ul className="mt-6">
              <ScrollArea className="h-[calc(100vh-336px)]">
                {departments?.data.map((dp) => (
                  <li
                    className="flex min-h-9 items-center justify-between border-b px-3"
                    key={dp.id}
                  >
                    <span
                      className="w-full cursor-pointer"
                      onClick={() =>
                        manageDepartmentMembersDialogRef.current?.open(dp.id)
                      }
                    >
                      {dp.name}
                    </span>

                    <ConfirmDialog
                      onConfirm={() => onRemoveHandle(dp.id)}
                      title={t('Common.deleteDepartment')}
                      type="delete"
                      description={t('Common.confirmDeleteDepartment')}
                    >
                      <Button
                        type="button"
                        variant="ghost"
                        size="iconSm"
                        className="size-8"
                        disabled={isPendingRemove}
                      >
                        <Trash2 className="size-5 text-destructive" />
                      </Button>
                    </ConfirmDialog>
                  </li>
                ))}
              </ScrollArea>
            </ul>

            {departments && departments.totalCount > 0 && (
              <Pagination
                className="mt-4"
                changeCurrentPage={(page) =>
                  setPaginationDepartment((prev) => ({
                    ...prev,
                    page: page - 1,
                  }))
                }
                totalSize={departments && departments.totalCount ? departments.totalCount : 0}
                sizePerPage={paginationDepartment.perPage}
                currentPage={paginationDepartment.page + 1}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
      <ManageDepartmentMembersDialog onRefresh={() => {}} ref={manageDepartmentMembersDialogRef} />
    </>
  );
});

ManageDepartmentsDialog.displayName = 'ManageDepartmentsDialog';

export { ManageDepartmentsDialog };
